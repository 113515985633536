import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import "../css/cover.css";
const Container = styled.div`
  background: url(medical-equipment-4099430_1280.jpg);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  color: #fff;
`;

const Wrapper = styled.div`
  background: #00000082;
  display: flex;
  flex: column;
  justify-content: center;
  align-items: center;
`;

const ItemContainer = styled.div`
  margin-top: -5rem;
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

function Home() {
  return (
    <div>
      <main>
        <br />
        <br />
        <section>
          <h2>Privacy Policy</h2>
          <p>
            Your privacy is important to us, and we are committed to protecting
            your personal information.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Information We Collect</h2>
          <p>
            We may collect personal and non-personal information when you use
            our website or services. This includes your name, email address,
            contact details, and other relevant data.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>How We Use Your Information</h2>
          <p>
            We use the information we collect to provide and improve our
            services, communicate with you, and ensure a secure and personalized
            experience.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Information Sharing</h2>
          <p>
            We may share your information with trusted third parties to
            facilitate our services or as required by law. We do not sell or
            rent your personal information to third parties.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Your Choices</h2>
          <p>
            You have the right to review, update, and delete your personal
            information. You can also choose to opt out of receiving
            communications from us.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Security Measures</h2>
          <p>
            We implement security measures to protect your data from
            unauthorized access, alteration, or disclosure.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Changes to This Policy</h2>
          <p>
            We may update our privacy policy to reflect changes in our
            practices. We recommend reviewing this page periodically for any
            updates.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns regarding our privacy
            practices, please contact us at
            <a href="mailto:bookings@clinicpluswtb.co.za">
              bookings@clinicpluswtb.co.za
            </a>
            or call us at <a href="tel:+27136562020">+27136562020</a>
          </p>
        </section>
      </main>
    </div>
  );
}

export default Home;

// <Row>
//   <Col md="8" sm="10">
//     <img src="/cp-logo-full-white.svg" />
//     <h1>/h1>
//     <h3>Supporting healthy employees and healthy businesses</h3>
//     <br />
//     <p>
//
//     </p>
//     <Button variant="danger" href="/login" size="lg">
//       Login
//     </Button>
//   </Col>
// </Row>
