import { isEmpty, isNil } from "ramda";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { save, selectUser } from "./store/user";
import cookies from "js-cookie";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

const exists = (i) => !isNil(i) && !isEmpty(i);

const StyledBody = styled.div`
  height: 60vh;
  overflow: scroll;
`;

function App({ socket }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState();
  const [companies, setCompanies] = useState();
  const [interactions, setInteractions] = useState(null);
  const [showMobileCollapseApps, setShowMobileCollapseApps] = useState(false);
  const [showMobileCollapseComps, setShowMobileCollapseComps] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const toggleShowMobileCollapseApps = () =>
    setShowMobileCollapseApps(!showMobileCollapseApps);
  const toggleShowMobileCollapseComps = () =>
    setShowMobileCollapseComps(!showMobileCollapseComps);

  const user = cookies.get("clinicplus_client_logged_in_user");

  console.log("dash cookie user", user);
  useEffect(() => {
    console.log("use effect socket", socket);
    if (
      exists(user) &&
      exists(socket) &&
      isNil(appointments) &&
      isNil(companies) &&
      requestSent === false
    ) {
      console.log("dashboard gettin user data");
      setRequestSent(true);
      socket.emit("GET_USER", { id: user });
      socket.on("RECEIVE_USER", (u) => {
        console.log("dashboard got user data", u);
        const { appointmentsManaging, companiesManaging } = u;
        const allAppointments = appointmentsManaging;
        const allCompanies = companiesManaging;
        setAppointments(allAppointments);
        setCompanies(allCompanies);
        dispatch(save(u));
      });
    }
  }, [socket]);

  if (!exists(user)) {
    navigate("/");
  }
  return (
    <div className="container" id="appElement">
      <div className="pagetitle mt-3 mb-3">
        <h1>Dashboard</h1>
        <p>
          {" "}
          Below here you will find a list of appointments and companies you have
          created{" "}
        </p>
      </div>

      <div className="row">
        <div className="col-lg-6 col-xl-6 d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block">
          <div className="card">
            <StyledBody className="card-body">
              <h3 className="card-title">Appointments</h3>
              {(isNil(appointments) || isEmpty(appointments)) && (
                <p className="message">
                  <i>No appointments to show</i>
                </p>
              )}
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Appointment ID</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments &&
                    appointments.map((a) => (
                      <tr>
                        <td>{a.company}</td>
                        <td>{a.id}</td>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={`/appointment/${a.id}`}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </StyledBody>
            <div className="card-footer">
              <Link className="btn btn-primary" to="/appointment/create">
                New Appointment
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-6 d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block">
          <div className="card">
            <StyledBody className="card-body">
              <h3 className="card-title">Companies</h3>
              {(isNil(companies) || isEmpty(companies)) && (
                <p className="message">
                  <i>No companies to show</i>
                </p>
              )}
              <table class="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies &&
                    companies.map((c) => (
                      <tr>
                        <td>{c.name}</td>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={`/company/edit/${c.id}`}
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </StyledBody>
            <div className="card-footer">
              <Link className="btn btn-primary" to="/company/create">
                New Company
              </Link>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 d-lg-none d-xl-none">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Appointments</h3>
              <Button
                onClick={toggleShowMobileCollapseApps}
                aria-controls="mobile-apps-collapse"
                aria-expanded={showMobileCollapseApps}
                className="btn-block mb-3"
                variant="outline-dark"
              >
                {showMobileCollapseApps ? "Hide" : "List all appointments"}
              </Button>
              <Collapse in={showMobileCollapseApps}>
                <div id="mobile-apps-collapse">
                  <hr />
                  {(isNil(appointments) || isEmpty(appointments)) && (
                    <p className="message">
                      <i>No appointments to show</i>
                    </p>
                  )}
                  {appointments &&
                    appointments.map((a) => (
                      <Fragment>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <p>
                              <strong>Company: </strong> {a.company}
                            </p>
                            <p>
                              <strong>ID: </strong>
                              {a.id}
                            </p>
                            <Link
                              className="btn btn-sm btn-warning btn-block"
                              to={`/appointment/${a.id}`}
                            >
                              View
                            </Link>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                </div>
              </Collapse>
            </div>
            <div className="card-footer">
              <Link
                className="btn btn-primary btn-block"
                to="/appointment/create"
              >
                New Appointment
              </Link>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 d-lg-none d-xl-none">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Companies</h3>
              <Button
                onClick={toggleShowMobileCollapseComps}
                aria-controls="mobile-companies-collapse"
                aria-expanded={showMobileCollapseComps}
                className="btn-block mb-3"
                variant="outline-dark"
              >
                {showMobileCollapseComps ? "Hide" : "Show all"}
              </Button>
              <Collapse in={showMobileCollapseComps}>
                <div id="mobile-companies-collapse">
                  <hr />
                  {(isNil(companies) || isEmpty(companies)) && (
                    <p className="message">
                      <i>No companies to show</i>
                    </p>
                  )}
                  {companies &&
                    companies.map((c) => (
                      <Fragment>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <p>
                              <strong>Company: </strong> {c.name}
                            </p>
                            <Link
                              className="btn btn-sm btn-warning btn-block"
                              to={`/company/edit/${c.id}`}
                            >
                              Edit
                            </Link>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                </div>
              </Collapse>
            </div>
            <div className="card-footer">
              <Link className="btn btn-primary btn-block" to="/company/create">
                New Company
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
