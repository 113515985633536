import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

function SimpleLayout({ children, selected }) {
  return (
    <Fragment>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="ClinicPlus - Your Partner in Occupational Health Management and Consulting"
        />
        <meta
          name="keywords"
          content="occupational health, consulting, wellness programs, workplace health"
        />
        <meta name="author" content="ClinicPlus Team" />
        <title>
          ClinicPlus - Occupational Health Management and Consulting
        </title>

        <link href="/assets/img/favicon.png" rel="icon" />
        <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link
          href="/assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="/assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link href="/assets/vendor/quill/quill.snow.css" rel="stylesheet" />
        <link href="/assets/vendor/quill/quill.bubble.css" rel="stylesheet" />
        <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="/assets/vendor/simple-datatables/style.css"
          rel="stylesheet"
        />

        <link href="/assets/css/style2.css" rel="stylesheet" />
      </Helmet>
      <div className="main-container">
        <div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
          <header className="masthead mb-auto">
            <div className="inner">
              <h3 className="masthead-brand">ClinicPlus</h3>
              <nav className="nav nav-masthead justify-content-center">
                <a
                  className={`nav-link ${selected === "home" ? "active" : ""}`}
                  href="/"
                >
                  Home
                </a>
                <a
                  className={`nav-link ${selected === "about" ? "active" : ""}`}
                  href="/about"
                >
                  About
                </a>
              </nav>
            </div>
          </header>

          <main role="main" className="inner">
            {children}
          </main>

          <footer className="mastfoot mt-auto">
            <div className="inner">
              <p>
                &copy; 2023 ClinicPlus. All rights reserved. | Built with ❤️ By{" "}
                <a href="https://touch.net.za" target="_blank">
                  Midas Touch Technologies
                </a>{" "}
                <a href="/privacy">Privacy Policy</a> |{" "}
                <a href="/terms">Terms Of Service</a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default SimpleLayout;
