import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import "../css/cover.css";
const Container = styled.div`
  background: url(medical-equipment-4099430_1280.jpg);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  color: #fff;
`;

const Wrapper = styled.div`
  background: #00000082;
  display: flex;
  flex: column;
  justify-content: center;
  align-items: center;
`;

const ItemContainer = styled.div`
  margin-top: -5rem;
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

function Home() {
  return (
    <div className="cover-container">
      <br />
      <br />
      <h1 class="cover-heading my-4">
        Occupational Health Management and Consulting
      </h1>
      <br />
      <p class="lead my-4">
        At ClinicPlus, we are dedicated to fostering healthy employees and
        thriving businesses. Our client booking system is designed to streamline
        the process of scheduling appointments and accessing our comprehensive
        occupational health services. Whether you're a new client or a returning
        one, our user-friendly platform ensures a seamless experience.
      </p>
      <br />
      <p class="lead my-4">
        <a href="/register" class="btn btn-lg btn-warning mr-3">
          Signup
        </a>
        <a href="/login" class="btn btn-lg btn-danger mx-3">
          login
        </a>
      </p>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Home;

// <Row>
//   <Col md="8" sm="10">
//     <img src="/cp-logo-full-white.svg" />
//     <h1>/h1>
//     <h3>Supporting healthy employees and healthy businesses</h3>
//     <br />
//     <p>
//
//     </p>
//     <Button variant="danger" href="/login" size="lg">
//       Login
//     </Button>
//   </Col>
// </Row>
