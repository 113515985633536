import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

function Home() {
  return (
    <div>
      <br />
      <br />
      <p>
        At ClinicPlus, we're more than just a company – we're your dedicated
        partners in fostering a healthier and more productive workplace. With a
        strong focus on Occupational Health Management and Consulting, we are
        committed to supporting both employees and businesses on their journey
        to optimal well-being.
      </p>
      <br />
      <br />
      <h4>Our Vision</h4>
      <p>
        Our vision is to create a work environment where health is prioritized
        and integrated seamlessly into the fabric of everyday operations. We
        envision a world where employees thrive physically, mentally, and
        emotionally, leading to increased productivity, job satisfaction, and
        overall business success.
      </p>
      <br />
      <br />
      <h4>Who we are</h4>
      <p>
        ClinicPlus is a South Africa-based leader in Occupational Health
        Management and Consulting. With a team of experienced medical
        professionals, wellness experts, and technology enthusiasts, we bring a
        comprehensive approach to enhancing workplace health and safety.
      </p>
      <br />
      <br />
      <h4>What Sets Us Apart</h4>
      <ol>
        <li>
          <strong>Expertise:</strong> Our team of qualified medical
          professionals and consultants are well-versed in the complexities of
          occupational health. We stay up-to-date with industry regulations and
          best practices to ensure that our clients receive accurate and
          reliable guidance.
        </li>
        <li>
          <strong>Tailored Solutions:</strong> We understand that every business
          is unique. That's why we offer customized solutions that address the
          specific needs and challenges of each client. From small enterprises
          to large corporations, our approach is adaptable and effective.
        </li>
        <li>
          <strong>Holistic Approach: </strong>Health extends beyond just
          physical well-being. ClinicPlus takes a holistic approach that
          considers mental and emotional health as integral components of
          overall well-being. Our wellness programs reflect this commitment.
        </li>
        <li>
          <strong>Cutting-Edge Technology:</strong> We leverage technology to
          simplify the process of booking appointments, accessing health
          records, and tracking progress. Our online platform ensures
          convenience and efficiency for both clients and their employees.
        </li>
      </ol>
      <br />
      <br />
      <h4>Our Services</h4>
      <ul>
        <li>
          <strong>Medical Assessments: </strong>From pre-employment medicals to
          regular health check-ups, we provide a range of medical assessments
          that promote a healthy workforce.
        </li>
        <li>
          <strong>Wellness Programs:</strong> Our wellness initiatives are
          designed to educate, motivate, and empower employees to make positive
          lifestyle choices.
        </li>
        <li>
          <strong>Health and Safety Consultations:</strong> We offer expert
          guidance on creating safe and compliant work environments, reducing
          workplace risks, and improving overall occupational health standards.
        </li>
        <li>
          <strong>Training and Workshops:</strong> Through interactive workshops
          and training sessions, we equip both employees and management with the
          knowledge and skills needed to maintain a healthy workplace.
        </li>
      </ul>
      <br />
      <br />
      <h4>Contact Us</h4>
      <p>
        Ready to embark on a journey towards a healthier and more productive
        workforce? Get in touch with ClinicPlus today. You can reach us at{" "}
        <a href="mailto:bookings@clinicpluswtb.co.za">
          bookings@clinicpluswtb.co.za
        </a>
        or call us at <a href="tel:+27136562020">+27136562020</a>.
      </p>
    </div>
  );
}

export default Home;

// <Row>
//   <Col md="8" sm="10">
//     <img src="/cp-logo-full-white.svg" />
//     <h1>/h1>
//     <h3>Supporting healthy employees and healthy businesses</h3>
//     <br />
//     <p>
//
//     </p>
//     <Button variant="danger" href="/login" size="lg">
//       Login
//     </Button>
//   </Col>
// </Row>
