import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import "../css/cover.css";
const Container = styled.div`
  background: url(medical-equipment-4099430_1280.jpg);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  color: #fff;
`;

const Wrapper = styled.div`
  background: #00000082;
  display: flex;
  flex: column;
  justify-content: center;
  align-items: center;
`;

const ItemContainer = styled.div`
  margin-top: -5rem;
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

function Home() {
  return (
    <div>
      <main>
        <br />
        <br />
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to ClinicPlus's Terms of Service. By using our website and
            services, you agree to comply with these terms and conditions.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Use of Our Services</h2>
          <p>
            You may use our services for lawful purposes only. You agree not to
            engage in any activities that may disrupt or harm our website or
            services.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Intellectual Property</h2>
          <p>
            All content on our website, including text, graphics, logos, and
            images, is the property of ClinicPlus and is protected by applicable
            intellectual property laws.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Privacy</h2>
          <p>
            Your use of our services is subject to our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Disclaimer</h2>
          <p>
            We strive to provide accurate and up-to-date information, but we do
            not guarantee the accuracy, completeness, or reliability of our
            content. Our services are provided "as is" without any warranties.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Limitation of Liability</h2>
          <p>
            ClinicPlus and its affiliates shall not be liable for any direct,
            indirect, incidental, consequential, or punitive damages arising
            from your use of our services.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Termination</h2>
          <p>
            We reserve the right to suspend or terminate your access to our
            services if you violate these terms of service or engage in any
            prohibited activities.
          </p>
        </section>
        <br />
        <br />
        <section>
          <h2>Changes to Terms</h2>
          <p>
            We may update our terms of service from time to time. By continuing
            to use our services, you agree to be bound by the most recent
            version of these terms.
          </p>
        </section>
      </main>
    </div>
  );
}

export default Home;

// <Row>
//   <Col md="8" sm="10">
//     <img src="/cp-logo-full-white.svg" />
//     <h1>/h1>
//     <h3>Supporting healthy employees and healthy businesses</h3>
//     <br />
//     <p>
//
//     </p>
//     <Button variant="danger" href="/login" size="lg">
//       Login
//     </Button>
//   </Col>
// </Row>
