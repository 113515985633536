import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AddAppointment from "./pages/addAppointment";
import AddCompany from "./pages/addCompany";
import Appointment from "./pages/appointments/appointment";
import AppointmentEditor from "./pages/appointments/appointment/edit";
import AppointmentCreator from "./pages/appointments/appointment/create";
import AppointmentQuote from "./pages/appointments/appointment/quote";
import Company from "./pages/companies";
import CompanyEditor from "./pages/companies/company/edit";
import CompanyCreator from "./pages/companies/company/create";
import Settings from "./pages/settings";
import Messages from "./pages/messages";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import Home from "./pages/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import styled from "styled-components";
import Layout from "./components/layout";
import BareLayout from "./components/layout/bare";
import { useNavigate } from "react-router-dom";
import About from "./pages/about";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";

import io from "socket.io-client";

import { Provider } from "react-redux";
import { store } from "./store";
import SimpleLayout from "./components/layout/simple";
import "./css/style.css";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Main = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socektOptions = {
      path: "/socket.io",
      transports: ["websocket"],
      secure: true,
    };
    const newSocket = io(`${process.env.REACT_APP_IO_SERVER}`, socektOptions);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <SimpleLayout>
              <Login socket={socket} />
            </SimpleLayout>
          }
        />
        <Route
          path="/logout"
          element={
            <SimpleLayout>
              <Logout socket={socket} />
            </SimpleLayout>
          }
        />
        <Route
          path="/register"
          element={
            <SimpleLayout>
              <Register socket={socket} />
            </SimpleLayout>
          }
        />
        <Route
          path="/about"
          element={
            <SimpleLayout selected="about">
              <About />
            </SimpleLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <SimpleLayout selected="privacy">
              <Privacy />
            </SimpleLayout>
          }
        />
        <Route
          path="/"
          element={
            <SimpleLayout selected="home">
              <Home />
            </SimpleLayout>
          }
        />
        <Route
          path="/terms"
          element={
            <SimpleLayout selected="terms">
              <Terms />
            </SimpleLayout>
          }
        />
        <Route
          path="/app"
          element={
            <Layout socket={socket}>
              <App socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/appointment/:appId"
          element={
            <Layout socket={socket}>
              <Appointment socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/appointment/edit/:appId"
          element={
            <Layout socket={socket}>
              <AppointmentEditor socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/appointment/quote/:appId"
          element={
            <BareLayout>
              <AppointmentQuote socket={socket} />
            </BareLayout>
          }
        />
        <Route
          path="/appointment/create"
          element={
            <Layout socket={socket}>
              <AppointmentCreator socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/company/create"
          element={
            <Layout socket={socket}>
              <CompanyCreator socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/company/edit/:companyId"
          element={
            <Layout socket={socket}>
              <CompanyEditor socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout socket={socket}>
              <Settings socket={socket} />
            </Layout>
          }
        />
        <Route
          path="/messages"
          element={
            <Layout socket={socket}>
              <Messages socket={socket} />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
