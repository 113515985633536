import { isEmpty, isNil } from "ramda";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { save, selectUser } from "../../store/user";
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";
import cookies from "js-cookie";

const Container = styled.div`
  height: 100vh;
  padding: 50px 0px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background: #343940;
  color: #fff;
  margin-top: 10vh;
  margin-bottom: 10vh;

  background: #fff;
  background-size: cover;
`;

const Wrapper = styled.div`
  backdrop-filter: blur(16px);
  width: 40%;
  padding: 40px;
  height: 500px;
  border-radius: 10px;
  background: #343940;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const exists = (i) => !isNil(i) && !isEmpty(i);

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #80808052;
  box-shadow: -2px 3px 7px 1px #cfcfcf0a;
  border-radius: 5px;
  background: #dddddd;
  font-size: 14px;
`;

const Error = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  height: 40px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
`;

const LinkA = styled.a`
  margin-top: 20px;
  &:hover {
    color: lightblue;
    cursor: pointer;
  }
`;

function App({ socket }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  const doLogin = () => {
    setLoading(true);
    socket.emit("DO_LOGIN", { email, password });
    socket.on("RECEIVE_LOGIN_USER_FAILED", (user) => {
      setLoading(false);
      setError(user.error);
    });
    socket.on("RECEIVE_LOGIN_USER_SUCCESS", (user) => {
      dispatch(save(user));
      cookies.set("clinicplus_client_logged_in_user", user.id, { expires: 1 });
      setError("");
      setLoading(false);
      navigate("/app");
    });
  };

  console.log("loading", loading);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-left: 290px;
  `;
  return (
    <>
      <h1 className="my-5 dislplay-5">Bookings</h1>
      <h3>Client Login</h3>
      <p className="lead">
        Please enter your registered email address and password to access your
        account.
      </p>
      <InputWrapper>
        <Label>Email</Label>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>Password</Label>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
        />
      </InputWrapper>
      <Error>{error && error}</Error>

      {loading === true && (
        <p>
          Please wait... <br />
        </p>
      )}

      <p class="lead">
        <a onClick={doLogin} class="btn btn-lg btn-danger mr-3">
          Login
        </a>
      </p>
      <div className="my-5">
        <p class="lead my-5">
          Don't have an account ?
          <br />
          <a
            onClick={() => navigate("/register")}
            class="btn btn-outline-warning btn-lg my-3"
          >
            Register
          </a>
        </p>
        <p className="lead">
          By logging in, you gain access to a range of features and services
          designed to support your occupational health needs. If you encounter
          any issues or need assistance, please contact our support team at{" "}
          <a href="mailto:bookings@clinicpluswtb.co.za">
            bookings@clinicpluswtb.co.za
          </a>
        </p>
      </div>
      <br />
    </>
  );
}

export default App;
